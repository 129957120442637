import React, { useState } from "react"
import Layout from "../components/Layout"
import NewsIllustration from "../images/news.svg"
import SplitView from "../components/SplitView"
import { Modal } from "../components/Modal"
import NewsletterForm from "../components/NewsletterForm"
import Section from "../components/Section"
import { H1 } from "../components/text/H1"
import { H2 } from "../components/text/H2"
import SplitViewImage from "../components/SplitViewImage"
import { Button } from "../components/text/Button"

export interface NeuigkeitenProps {}

const timeline = [
  {
    headline: `neues App Design`,
    description: `ab dem <strong>20.04.2021</strong>`,
  },
  {
    headline: `Erinnerungsfunktion für Patient*innen`,
    description: `ab dem <strong>01.05.2021</strong>`,
  },
  {
    headline: `CE-Kennzeichnung als Medizinprodukt`,
    description: `Erhalt der Kennzeichnung bis zum <strong>15.05.2021</strong>`,
  },
  {
    headline: `Abgabe DiGA Antrag BfArM`,
    description: `bis zum <strong>15.06.2021</strong>`,
  },
  {
    headline: `Zulassung bei GKV`,
    description: `ab dem <strong>15.09.2021</strong>`,
  },
  {
    headline: `Katalog vorgefertigter therapeutischer Hausaufgaben`,
    description: `ab dem <strong>20.10.2021</strong>`,
  },
]

const Neuigkeiten: React.SFC<NeuigkeitenProps> = () => {
  const [showForm, setShowForm] = useState(false)
  return (
    <Layout>
      <SplitView
        elementLeft={
          <>
            <H1 text="Neuigkeiten" />
            <p className="mb-8 text-gray-400 leading-relaxed">
              Auf dieser Seite werden wir Sie auf dem Laufenden halten. Wenn Sie
              über Neuigkeiten regelmäßig informiert werden wollen, können Sie
              sich in unseren Newsletter eintragen oder Neuigkeiten direkt in
              der App unter "Einstellungen-{">"}Neuigkeiten" ansehen.
            </p>
            <div>
              <Button
                text="In den Newsletter eintragen"
                onClick={() => {
                  setShowForm(!showForm)
                }}
              />
            </div>
          </>
        }
        elementRight={
          <SplitViewImage
            src={NewsIllustration}
            alt="Neuigkeiten verbreiten Illustration"
          />
        }
      />

      <Modal
        show={showForm}
        setShow={() => {
          setShowForm(!showForm)
        }}
        content={
          <NewsletterForm
            onCancel={() => {
              setShowForm(!showForm)
            }}
          />
        }
      />

      <Section background>
        <H2 text="Zeitplan" />

        <table className="table-auto shadow rounded">
          <tr className="bg-primary-600 text-white rounded shadow">
            <th className="py-2">Wichtige Ereignisse</th>
            <th className="py-2">Beschreibung</th>
          </tr>
          {timeline.map((row, index) => (
            <tr className={index % 2 === 0 ? "bg-white" : "bg-gray-200"}>
              <td
                className="py-2 pl-4 pr-6"
                dangerouslySetInnerHTML={{ __html: row.headline }}
              />
              <td
                className="py-2 pr-4 pl-6"
                dangerouslySetInnerHTML={{ __html: row.description }}
              />
            </tr>
          ))}
        </table>
      </Section>
    </Layout>
  )
}

export default Neuigkeiten
